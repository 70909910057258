<template>
    <div class="loader-wrapper" v-show="isLoading">
        <loader></loader>
    </div>
</template>

<script>
import Loader from "@/components/basic/Loader.vue"
import { logOut } from "@/services/intratone/auth.js"

import { useToast } from "vue-toastification"
const toast = useToast()

export default {
    name: "Logout",
    components: {
        Loader,
    },
    data() {
        return {
            // Show loader
            isLoading: true,
        }
    },
    methods: {
        logout(data) {
            const dataStoreToKeep = {
                login: this.$store.getters["account/getLogin"],
                residenceCard:
                    this.$store.getters["account/getResidenceDisplayChoice"],
                accessCard: this.$store.getters["account/getDisplayKeyCard"],
                keyCard: this.$store.getters["account/getDisplayAccessCard"],
                uuid: this.$store.getters["auth/getUuid"],
                signature: this.$store.getters["auth/getSignature"],
                code: this.$store.getters["auth/getCode"],
                param: this.$store.getters["auth/getParam"],
            }
            const vuexData = JSON.parse(localStorage.getItem("vuex"))

            this.$store.commit("disconnect")
            const tempValue = localStorage.getItem("closeV1warning")

            localStorage.clear()
            localStorage.setItem("closeV1warning", tempValue)

            if (vuexData.account["lastDateNotification"]) {
                localStorage.setItem(
                    "lastDateNotification",
                    vuexData.account["lastDateNotification"]
                )
            }
            this.storeDataToKeep(dataStoreToKeep)

            window.Deconnect(1)

            this.$router.push({
                name: "Login",
            })

            // Alert : connected
            toast.info(this.$t("alerts.connection.disconnected"), {
                icon: "fas fa-power-off",
            })
        },
        async APIlogout() {
            try {
                // Requesting API for logout
                let LogoutResponse = await logOut()

                if (LogoutResponse === false || LogoutResponse.data.error) {
                    throw new Error(this.$t("errors.0"))
                }

                this.logout()
            } catch (error) {
                console.log(error)
                toast.error(error.message, {
                    icon: "fas fa-exclamation-circle",
                    timeout: 10000,
                })
                this.logout()
            }
        },
        storeDataToKeep(data) {
            this.$store.commit("account/setLogin", data.login)
            this.$store.commit(
                "account/setResidenceDisplayChoice",
                data.residenceCard
            )
            this.$store.commit("account/setDisplayKeyCard", data.accessCard)
            this.$store.commit("account/setDisplayAccessCard", data.keyCard)
            this.$store.commit("auth/setUuid", data.uuid)
            this.$store.commit("auth/setSignature", data.signature)

            if (data.code) {
                for (let i = 0; i < data.code.length; i++) {
                    this.$store.commit("auth/setCode", data.code[i])
                }
            }
        },
    },
    mounted() {
        if (this.$route.query !== undefined) {
            if (this.$route.query.call === "1") {
                this.APIlogout()
            } else {
                this.logout()
            }
        } else {
            this.logout()
        }
    },
}
</script>

<style lang="scss" scoped>
.loader-wrapper {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
